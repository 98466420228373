import { ArrowRight } from '@phosphor-icons/react'
import { Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FocusSkillItem } from './focus-skill-item'
import { FocusSkillsSectionVm } from './focus-skills-section-vm'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'
import { useStore } from 'store/context'

type FocusSkillsSectionProps = {
  focusedUserSkills: UserSkill[]
  suggestedUserSkills: UserSkill[]
  user: User
}

export const FocusSkillsSection = observer((props: FocusSkillsSectionProps) => {
  const { focusedUserSkills, suggestedUserSkills, user } = props

  const { actions } = useStore()

  const vm = React.useMemo(() => {
    return new FocusSkillsSectionVm(
      focusedUserSkills,
      suggestedUserSkills,
      user
    )
  }, [focusedUserSkills, suggestedUserSkills, user])

  return (
    <div className="p-6">
      <Link
        href={user.profileUrl}
        underline={false}
        className="flex items-center justify-between p-0 mb-2"
        data-turbo-frame="content"
        data-turbo-action="advance"
      >
        <div className="font-bold text-base text-gray-900">Focus Skills</div>
        <ArrowRight className="h-4 text-gray-200 w-4" weight="bold" />
      </Link>
      <div className="flex flex-col gap-y-4">
        {vm.showErrorMessage && (
          <div className="text-gray-600 text-xs">{vm.errorMessage}</div>
        )}
        {vm.showSuggestedSkills && focusedUserSkills.length === 0 && (
          <div className="text-gray-600">Suggested skills</div>
        )}
        <ul className="flex flex-col divide-gray-100 divide-solid divide-y list-none p-0">
          {focusedUserSkills.map((userSkill) => (
            <FocusSkillItem
              key={userSkill.id}
              userSkill={userSkill}
              selected={true}
              actions={actions.forSkillAndUser(userSkill.skill.id, user.id)}
              vm={vm}
            />
          ))}
          {vm.showSuggestedSkills &&
            suggestedUserSkills.map((userSkill) => (
              <FocusSkillItem
                key={userSkill.id}
                selected={false}
                userSkill={userSkill}
                actions={actions.forSkillAndUser(userSkill.skill.id, user.id)}
                vm={vm}
              />
            ))}
        </ul>
      </div>
    </div>
  )
})
