import { observer } from 'mobx-react-lite'
import { ArrowRight, Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { Action } from 'store/modules/actions'
import { ActionsTable } from 'components/actions-table'
import { CreateFormModalButton } from 'components/create-form-modal'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'
import { Link } from 'src/design-system'

type ActionsSectionProps = {
  actions: Action[]
  focusSkillIds: string[]
  user: User
  userSkills: UserSkill[]
}

export const ActionsSection = observer((props: ActionsSectionProps) => {
  const { actions, focusSkillIds, user, userSkills } = props

  const source = 'growth-side-panel'

  const hasUserSkills = userSkills.length > 0

  if (!user) return null

  return (
    <>
      <div className="p-6 pl-0 pb-14">
        <div data-element-id="actions-section" className="pl-6">
          <div className="flex items-center justify-between mb-2">
            <div className="font-bold text-base text-gray-900">Actions</div>
            <div className="flex gap-2">
              {hasUserSkills && (
                <CreateFormModalButton
                  title="Add action"
                  user={user}
                  source={source}
                  className="bg-white flex items-center justify-center p-0"
                  label={
                    <Plus className="h-4 text-theme-50 w-4" weight="bold" />
                  }
                  tabs={['action']}
                />
              )}
              <Link
                className="flex items-center p-0"
                href={user.actionsUrl}
                underline={false}
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                <ArrowRight className="h-4 text-gray-200 w-4" weight="bold" />
              </Link>
            </div>
          </div>
          {actions.length > 0 && (
            <ActionsTable
              actions={actions}
              characterLimit={90}
              editable
              focusSkillIds={focusSkillIds}
              skillsCharacterLimit={26}
              source={source}
            />
          )}
          {actions.length === 0 && (
            <>
              {hasUserSkills && (
                <>
                  <div className="mb-5 text-gray-600 text-xs">
                    Actions help you record what you’ll do next to progress in
                    your skill and career growth.
                  </div>
                  <CreateFormModalButton
                    className="btn max-w-none text-xs w-full"
                    label="Add Action"
                    source={`${source}-empty-state`}
                    tabs={['action']}
                    user={user}
                    variant="outline"
                  />
                </>
              )}
              {!hasUserSkills && (
                <div className="text-gray-600 text-xs">
                  {user.position
                    ? 'You need to add skills to your position before you can add Actions.'
                    : 'You need to add your position before you can add Actions.'}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
})
