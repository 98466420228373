import { Link, Tag } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { FocusSkillStar } from 'components/focus-skill-star'
import { UserSkill } from 'store/modules/user_skills'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { Action } from 'store/modules/actions'
import { observer } from 'mobx-react-lite'
import { FocusSkillsSectionVm } from './focus-skills-section-vm'
import { CircleProgressBar } from 'components/circle-progress-bar'

export type FocusSkillItemProps = {
  actions: Action[]
  selected: boolean
  userSkill: UserSkill
  vm: FocusSkillsSectionVm
}

export const FocusSkillItem = observer((props: FocusSkillItemProps) => {
  const { actions, userSkill, vm } = props

  const cardSource = `${userSkill.focused ? '' : 'suggested-'}focus-skill-item`

  return (
    <li className="border-l-0 border-r-0 flex flex-col gap-1.5 pb-3 pt-2">
      <Link
        className={cn('p-0', !userSkill.focused && 'opacity-50')}
        href={userSkill.skill.userSkillUrl}
        underline={false}
        data-turbo-frame="content"
        data-turbo-action="advance"
      >
        <div className="flex flex-col gap-y-1 truncate w-full">
          <div className="flex gap-1 items-center">
            <FocusSkillStar
              className="ml-0.5"
              editable={true}
              size={12}
              source={cardSource}
              userSkill={userSkill}
            />
            <div className="inline text-gray-900 text-sm truncate">
              {userSkill.skill.name}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              {userSkill.positionRequirementLevel && (
                <Tag className="text-gray-900 text-xs" variant="paper">
                  L{userSkill.positionRequirementLevel}
                </Tag>
              )}
              <CheckinStatusLabel status={userSkill.status} />
            </div>
            <div className="flex gap-1 items-center text-xs">
              <CircleProgressBar
                progressPercent={vm.progressPercent(actions)}
                size="md"
              />
              <div className="text-gray-600">{vm.actionStatus(actions)}</div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
})
