import { Action } from 'store/modules/actions'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'

export class FocusSkillsSectionVm {
  constructor(
    private focusedUserSkills: UserSkill[],
    private suggestedUserSkills: UserSkill[],
    private user: User
  ) {}

  get errorMessage() {
    if (this.user.position) {
      return 'You need to add skills to your position before you can add Focus Skills.'
    } else {
      return 'You need to add your position before you can add Focus Skills.'
    }
  }

  get showErrorMessage() {
    return (
      this.focusedUserSkills.length === 0 &&
      this.suggestedUserSkills.length === 0
    )
  }

  get showSuggestedSkills() {
    return (
      this.focusedUserSkills.length < 3 && this.suggestedUserSkills.length > 0
    )
  }

  actionStatus(actions: Action[]) {
    return `${actions.filter((action) => action.completed).length}/${
      actions.length
    }`
  }

  progressPercent(actions: Action[]) {
    if (actions.length > 0) {
      return (
        (actions.filter((action) => action.completed).length / actions.length) *
        100
      )
    } else {
      return 100
    }
  }
}
